import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../../components/PageWrapper";
import Hero from "../../../sections/webinars/Hero";
import Main from "../../../sections/webinars/Main";
import Features from "../../../sections/common/Features";
import VideoDemo from "../../../sections/common/VideoDemoNew";
import Wave from "../../../sections/common/Wave";
import WaveReverse from "../../../sections/common/WaveReverse";
import CTA from "../../../sections/CTA/CTA";
import SEO from '../../../components/SEO';
import metaImg from "../../../assets/image/metaimg/webinars.jpg"
const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Repurpose Online Webinars by making them Interactive"
          description="Distribute information and course materials in the Interactive Video"
          image={metaImg}
        />    
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=webinars'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=webinars'}></script>
        </Helmet>                     
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/learning">Learning</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li> 
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/learning/webinars">Webinars</Link>
              </li>                            
              </ol>
           </nav>
        </div>            
        <Hero />
        <WaveReverse pos="top" color="#0c426d" background="#fff" /> 
        <VideoDemo className="bg-digma-dark" name="Webinar in Turkey" projectId="31AAA783-308D-4200-8F1D-45C20F44DC47" />
        <Wave pos="bottom" color="#F7F9FC" background="#0c426d" /> 
        <Main className="grey" />
        <Features />
        <CTA className="bg-images" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
